import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Footer from "../components/footer.component";
import Header from "../components/header.component";

const styles = {
  main: {
   width: '100%',
   minHeight: '100vh',
  },
  container: {
    padding: "50px 10% 50px 10%"
  } 
};

const ConditionPage = () => {
  const breakpoints = useBreakpoint();
  
  const [displayVideoPresentation, setDisplayVideoPresentation] = React.useState(false);

  return (
      <main style={styles.main}>
        <Header title={"Conditions générales d'utilisation"} />

        <section style={styles.container}>
          <b>Les Conditions d’utilisation (« Conditions ») réglementent votre utilisation du service. En utilisant les Services, vous reconnaissez être lié(e) par les présentes Conditions.</b>
        
          <h3>1. Qui peut utiliser les Services ?</h3>
          <p>Vous ne pourrez utiliser les Services que si vous acceptez de signer un contrat vous liant à Memble et si vous êtes âgé d’au moins 13 ans. Si vous acceptez les présentes Conditions et que vous utilisez les Services au nom d’une société, une organisation, un État ou une autre entité juridique, vous déclarez et garantissez que vous êtes autorisé(e) à le faire et que vous avez l’autorité de lier cette entité aux présentes Conditions, auquel cas les mots "vous" et "votre" tels qu’ils sont utilisés dans les présentes Conditions se réfèrent à cette entité.</p>
        
        
         <h3>2. Confidentialité</h3>
         <p>Notre <a href="/privacy">Politique de confidentialité</a> décrit comment nous traitons les informations que vous nous fournissez lorsque vous utilisez Memble. Vous reconnaissez qu’en utilisant les Services, vous consentez à ce que nous collections et utilisions (selon les termes de la Politique de confidentialité) ces informations.</p>
        
          <h3>3. Contenu des Services</h3>
          <p>Vous êtes responsable de l’utilisation que vous faites des Services et de tout Contenu que vous fournissez, y compris de la conformité aux lois, règles et réglementations en vigueur. Vous ne devez fournir un Contenu que dans la mesure où cela ne vous gêne pas de le partager avec d’autres.</p>
          <p>Toute utilisation de votre compte est sous votre seule responsabilité. Vous ne pouvez pas utiliser ce service à des fins illégales, nuisibles, frauduleuses, offensantes ou pour transmettre, stocker, afficher, distribuer ou autrement rendre disponible un contenu portant atteinte aux droits de tiers, illégal, préjudiciable, soutenant ou encourageant la violence ou l'extrémisme violent, prônant la haine contre toute personne ou groupe de personnes en raison de leur race, religion, origine ethnique, sexe, identité de genre, sexualité orientation, handicap ou déficience, indécent, obscène, diffamatoire, calomnieux, harcelant, menaçant, frauduleux, offensant, permet le jeu en ligne ou incompatible avec les pratiques généralement acceptées de la communauté Internet, y compris, sans s'y limiter, la promotion ou la facilitation de la pornographie, l'offre ou la diffusion frauduleuse biens, services, stratagèmes ou promotions, spam, stratagèmes de gain d'argent rapide, stratagèmes ponzi et pyramidaux, hameçonnage ou ph l'armement et l'utilisation de contenus ou de technologies susceptibles d'endommager, d'interférer, d'intercepter subrepticement ou d'exproprier tout système, programme ou données, y compris les virus, chevaux de Troie, vers ou bombes à retardement.</p>
          <p>Nous nous réservons le droit de supprimer tout Contenu qui ne seraient pas en conformité avec notre politique.</p>
        
          <h3>3. Vos droits et Concession de droits sur le Contenu</h3>
          <p>Vous conservez vos droits sur tout Contenu que vous soumettez, publiez ou affichez sur ou via les Services. Ce qui est à vous vous appartient. Vous êtes le propriétaire de votre Contenu (ce qui inclut vos sons, photos et vidéos intégrés). Vous consentez à ce que vos groupes, ou contenus publiés sur un groupe public soit en accès libre, pour toute personne authentifier sur l'application.</p>
        
          <h4>4. Votre Compte</h4>
          <p>Vous devrez peut-être créer un compte pour utiliser Memble. Vous êtes responsable de la protection de votre compte ; nous vous invitons donc à utiliser un mot de passe fort et à limiter son utilisation à ce compte. Nous déclinons toute responsabilité en cas de perte ou de préjudice qui découlerait du non-respect par vous de ce qui précède.</p>
        
          <b>Date d’effet : 23 novembre 2021</b>
        </section>

        <Footer/>
      </main>
  )
}


export default ConditionPage
