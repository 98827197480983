import * as React from "react"
import { COLORS } from "../constants/colors";
import LogoSvg from '../images/memble-white.svg';

const styles = {
    header: {
        width: '80%',
        backgroundColor: COLORS.PRIMARY,
        color: 'white',
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        padding: "0 10% 0 10%"
    },
    logo: {
        width: "45px",
        height: "45px",
        marginRight: "10px"
    }
}
const HeaderComponent = (props) => {
    return (
        <header style={styles.header}>
            <a href="/">
                <img style={styles.logo} src={LogoSvg} />
            </a>
            <h3>{props.title}</h3>
        </header>
    )
}

export default HeaderComponent;